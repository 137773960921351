import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthGuardService } from '../guards/auth-guard.service';
import { Precaso } from '../../pages/precaso/precaso.model';
import { StorageService } from '../guards/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PrecasosService {

private token = this.storageService.getToken();

  httpOptions = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Authorization': 'Bearer ' + this.token,
    }
  };

  httpOptionsString = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Authorization': 'Bearer ' + this.token,
      'responseType': 'text'
    }
  };

  httpOptionsFile = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      //'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + this.token,
      'responseType': 'json'
    }
  };

  httpOptionsFileXML = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + this.token,
    },
    responseType: 'text' as 'json'
  };
  httpOptionsXML = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type':  'application/xhtml+xml',
      'Authorization': 'Bearer ' + this.token,
    },
    responseType: 'text' as 'json'
  };

  constructor(private http: HttpClient,
    private service: AuthGuardService,
    private storageService: StorageService) { }

  createPrecaso (precaso): Observable<Precaso> {
    const url = this.service.apiUrl + `/create-precase`;
    return this.http.post<Precaso>(url, precaso, this.httpOptions);
  }
  
  /*SIFM se añade Create precaso import*/ 
  createprecasoimp (precaso): Observable<any> {
    const url = this.service.apiUrl + `/create-imp`;
    return this.http.post<Precaso>(url, precaso, this.httpOptions);
  }

  getPrecasoList (): Observable<any> {
    const url = this.service.apiUrl + `/get-precase-list?limit=10&skip=0`;
    return this.http.get(url, this.httpOptions);
  }

  getPrecasoListPag (limit, skip, status): Observable<any> {
    const url = this.service.apiUrl + `/get-precase-list-pag?limit=${limit}&skip=${skip}&status=${status}`;
    return this.http.get(url, this.httpOptions);
  }

  getPrecasoListSearch (limit, skip, status, search): Observable<any> {
    const url = this.service.apiUrl + `/search-precase-pag?limit=${limit}&skip=${skip}&status=${status}&q=${search}`;
    return this.http.get(url, this.httpOptions);
  }

  getPrecase (id): Observable<any> {
    const url = this.service.apiUrl + `/get-precase-by-id?_id=${id}`;
    return this.http.get(url, this.httpOptions);
  }

  getPrecaseCaseId (name): Observable<any> {
    const url = this.service.apiUrl + `/get-precase?case_id=${name}`;
    return this.http.get(url, this.httpOptions);
  }

  updatePrecase (precaso): Observable<any> {
    // console.log(JSON.stringify(precaso));
    const url = this.service.apiUrl + `/update-precase`;
    return this.http.post(url, precaso, this.httpOptions);
  }

  deleteimp (datos): Observable<any> {
    // console.log(JSON.stringify(precaso));
    const url = this.service.apiUrl + `/delete-imp`;
    return this.http.post(url, datos, this.httpOptions);
  }

  uploadMedicines (form): Observable<any> {
    const url = this.service.apiUrl + `/load-med-list`;
    // console.log(form);
    // console.log(form.get('company'));
    // console.log(form.get('file'));
    return this.http.post(url, form, this.httpOptionsFile);
  }

  getListMedicines (company): Observable<any> {
    const url = this.service.apiUrl + `/get-med-list-by-company?company=${company}`;
    return this.http.get(url, this.httpOptions);
  }

  notifyPrecase (data): Observable<any> {
    const url = this.service.apiUrl + `/notify-precase`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptionsString);
  }

  verifyCase (data): Observable<any> {
    const url = this.service.apiUrl + `/verify-evaluation`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptions);
  }
  validPrecase (data): Observable<any> {
    const url = this.service.apiUrl + `/valid-precase`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptions);
  }

  rejectPrecase (data): Observable<any> {
    const url = this.service.apiUrl + `/reject-precase`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptionsString);
  }

  closePrecase (data): Observable<any> {
    const url = this.service.apiUrl + `/close-precase`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptionsString);
  }

  rejectCase (data): Observable<any> {
    const url = this.service.apiUrl + `/reject-case`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptionsString);
  }

  closeCase (data): Observable<any> {
    const url = this.service.apiUrl + `/close-case`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptionsString);
  }
  openCase (data): Observable<any> {
    const url = this.service.apiUrl + `/open-case`;
    // tslint:disable-next-line: label-position
    return this.http.post<any>(url, data, this.httpOptionsString);
  }

  getCodeMeddra (opt, text): Observable<any> {
    const url = this.service.apiUrl + `/get-llt?opt=${opt}&text=${text}`;
    return this.http.get(url, this.httpOptions);
  }
  getMeddraCode (opt, text): Observable<any> {
    const url = this.service.apiUrl + `/get-meddra-code?opt=${opt}&text=${text}`;
    return this.http.get(url, this.httpOptions);
  }

  getMeddraVersion (): Observable<any> {
    const url = this.service.apiUrl + `/get-meddra-v`;
    return this.http.get(url, this.httpOptions);
  }

  getMeddraJerar (code): Observable<any> {
    const url = this.service.apiUrl + `/get-llt-hierarchy?code=${code}`;
    return this.http.get(url, this.httpOptions);
  }

  getXML (code) {
    const url = this.service.apiUrl + `/export-xml?case_id=${code}`;
    return this.http.get<any>(url, this.httpOptionsXML);
  }

  async getXmlAsync(url) {
    try{
      let response = await fetch(`${url}`);
      if(response.ok) {
        return await response.blob();
      } else {
        console.log('Respuesta de red OK pero respuesta HTTP no OK');
      }
    }catch(err){
      console.error(err);
      // Handle errors here
    }
  }

  getRTF (code) {
    //SIFM - const url = this.service.apiUrl + `/rtf-case?case_unique_id=${code}`;
    const url = this.service.apiUrl + `/rtf-case?id=${code}`;
    return this.http.get<any>(url, this.httpOptionsFileXML);
  }
  getRTFCase (code) {
    const url = this.service.apiUrl + `/rtf-case?id=${code}`;
    return this.http.get<any>(url, this.httpOptionsFileXML);
  }

  getCIOMS (code) {
    //SIFM - const url = this.service.apiUrl + `/pdf-cioms-form?case_unique_id=${code}`;
    const url = this.service.apiUrl + `/pdf-cioms-form?id=${code}`;
    return this.http.get<any>(url, this.httpOptionsFileXML);
  }

  getDuplicates (data) {
    const url = this.service.apiUrl + `/detect-duplicate`;
    return this.http.post<any>(url, data, this.httpOptions);
  }
  getImportPossDup (data) {
    const url = this.service.apiUrl + `/import-poss-dup`;
    return this.http.post<any>(url, data, this.httpOptions);
  }
  getimportFu (data) {
    const url = this.service.apiUrl + `/import-fu`;
    return this.http.post<any>(url, data, this.httpOptions);
  }

}
