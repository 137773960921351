import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import 'hammerjs'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { AuthGuard } from './services/guards/auth.guard';
import { MatDialogModule } from '@angular/material';
import { LoadingSpinnerComponent } from './pages/loading-spinner/loading-spinner.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { DialogConfirmComponent } from './pages/precaso/dialog-confirm/dialog-confirm.component';
import { MaterialModule } from 'src/@fury/shared/material-components.module';


export function jwtTokenGetter() {
  return sessionStorage.getItem('access_token');
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
      tokenGetter: jwtTokenGetter,
      whitelistedDomains: ['localhost:4200'],
      blacklistedRoutes: ['http://localhost:4200/login']
  }
};

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    MatDialogModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Google Maps Module
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    JwtModule.forRoot(JWT_Module_Options),

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [AppComponent, LoadingSpinnerComponent, DialogConfirmComponent],
  entryComponents: [DialogConfirmComponent], 
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    AuthGuardService,
    AuthGuard,
    BnNgIdleService
  ]
})
export class AppModule {

}
