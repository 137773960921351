import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './services/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/authentication/login/login.module#LoginModule',
  },
  {
    path: 'register',
    loadChildren: './pages/authentication/register/register.module#RegisterModule',
  },
  {
    path: 'forgot-password',
    loadChildren: './pages/authentication/forgot-password/forgot-password.module#ForgotPasswordModule',
  },
  // {
  //   path: 'coming-soon',
  //   loadChildren: './pages/coming-soon/coming-soon.module#ComingSoonModule',
  // },
  {
    path: 'authenticate',
    loadChildren: './pages/authenticate/authenticate.module#AuthenticateModule',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
        pathMatch: 'full'
      },
      // {
      //   path: 'apps/inbox',
      //   loadChildren: './pages/apps/inbox/inbox.module#InboxModule',
      // },
      // {
      //   path: 'apps/calendar',
      //   loadChildren: './pages/apps/calendar/calendar.module#CalendarAppModule',
      // },
      // {
      //   path: 'apps/chat',
      //   loadChildren: './pages/apps/chat/chat.module#ChatModule',
      // },
      {
        path: 'components',
        loadChildren: './pages/components/components.module#ComponentsModule',
      },
      // {
      //   path: 'forms/form-elements',
      //   loadChildren: './pages/forms/form-elements/form-elements.module#FormElementsModule',
      // },
      // {
      //   path: 'forms/form-wizard',
      //   loadChildren: './pages/forms/form-wizard/form-wizard.module#FormWizardModule',
      // },
      // {
      //   path: 'icons',
      //   loadChildren: './pages/icons/icons.module#IconsModule',
      // },
      // {
      //   path: 'page-layouts',
      //   loadChildren: './pages/page-layouts/page-layouts.module#PageLayoutsModule',
      // },
      // {
      //   path: 'maps/google-maps',
      //   loadChildren: './pages/maps/google-maps/google-maps.module#GoogleMapsModule',
      // },
      // {
      //   path: 'tables/all-in-one-table',
      //   loadChildren: './pages/tables/all-in-one-table/all-in-one-table.module#AllInOneTableModule',
      // },
      // {
      //   path: 'drag-and-drop',
      //   loadChildren: './pages/drag-and-drop/drag-and-drop.module#DragAndDropModule'
      // },
      // {
      //   path: 'editor',
      //   loadChildren: './pages/editor/editor.module#EditorModule',
      // },
      // {
      //   path: 'blank',
      //   loadChildren: './pages/blank/blank.module#BlankModule',
      // },
      // {
      //   path: 'level1/level2/level3/level4/level5',
      //   loadChildren: './pages/level5/level5.module#Level5Module',
      // },
      {
        path: 'companies',
        loadChildren: './pages/companies/companies.module#CompaniesModule',
      },
      {
        path: 'users',
        loadChildren: './pages/users/users.module#UsersModule',
      },
      {
        path: 'precase',
        loadChildren: './pages/precaso/precaso.module#PrecasoModule',
      },
      {
        path: 'case',
        loadChildren: './pages/case/case.module#CaseModule',
      },
      {
        path: 'audit-trails',
        loadChildren: './pages/audit-trails/audit-trails.module#AuditTrailsModule',
      },
      {
        path: 'medicines',
        loadChildren: './pages/medicines/medicines.module#MedicinesModule',
      },
      {
        path: 'profile',
        loadChildren: './pages/profile/profile.module#ProfileModule',
      },   
      {
        path: 'reportes',
        loadChildren: './pages/reportes/reportes.module#ReportesModule',
      },           
      {
        path: 'role-requests',
        loadChildren: './pages/role-requests/role-requests.module#RoleRequestsModule',
      },   
      {
        path: 'import-case',
        loadChildren: './pages/import-case/import-case.module#ImportCaseModule',
      },
      {
        path: 'categorize',
        loadChildren: './pages/categorize/categorize.module#CategorizeModule',
      },
      {
        path: 'update-meddra',
        loadChildren: './pages/meddra/meddra.module#MeddraModule',
      }           
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
