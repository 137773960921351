import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../../../services/guards/auth-guard.service';
import { Router } from '@angular/router';
import { UserAgentApplication } from 'msal';
import { StorageService } from '../../../services/guards/storage.service';
import { LogoutService } from '../../../services/guards/auth-guard-logout.service';
import { isInteger } from 'lodash-es';



@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  myMSALObj = new UserAgentApplication({
    auth: {
        clientId: "4e240945-711b-4b7f-b222-547644ab8ae1",
        //clientId: "a0a9bd5e-6c4e-4925-9980-88a6af20a53e",
        authority: "https://login.microsoftonline.com/ntshub.onmicrosoft.com",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
  });
  
  isOpen: boolean;
  name: string;
  username: string;


  constructor(private router: Router, 
    private _authService: AuthGuardService,
    private _logoutservice:LogoutService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.name = this.storageService.getName();
    this.username = this.storageService.getUsername();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
    /*let sesion_user_id=this.storageService.getIdUser();
    let unique_user: {body:{register_action: number,sesion_user_id: string}};
    
    unique_user.body.register_action= 1;
    unique_user.body.sesion_user_id=sesion_user_id;

    this.userServ.register_activesession(unique_user)
    .subscribe(response2 => {
      this.myMSALObj.logout();
      this._authService.logout();
    },
    error => {
      console.log('Error Session', error);
    });
*/
    /*sifm esto es lo que había originalmente en el logout */
    this._logoutservice.logout();
    this.myMSALObj.logout();
  //this._authService.logout();
    this.router.navigate(['/']);
  }

  settings() {
    this.router.navigate(['/profile']);
  }

}
