// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  googleMapsApiKey: '',
  ///backend: 'http://localhost:4200', // Put your backend here
  ///apiUrl: 'https://api.preoiram.ntshub.es/api'

  // preprod
  //    backend: 'http://localhost:4201',
   //   apiUrl: 'https://pre.api.oiram.ntshub.es/api'
  

  // produccion
     // backend: 'https://oiram.ntshub.es/',
      backend: 'http://localhost:4202',
      apiUrl: 'https://api.oiram.ntshub.es/api'
  
};
