import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../../pages/users/user.model';
import { tap, catchError } from 'rxjs/operators';
import { AuthGuardService } from '../guards/auth-guard.service';
import { StorageService } from '../guards/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private token = this.storageService.getToken();

  httpOptions = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Authorization': 'Bearer ' + this.token,
    }
  };

  constructor(private http: HttpClient, 
    private service: AuthGuardService,
    private storageService: StorageService) { }

  getAllUsers(): Observable<any> {
    const url = this.service.apiUrl + `/get-all-users`;

    return this.http.get(url, this.httpOptions);


  }

  getUser (id): Observable<User> {
    const url = this.service.apiUrl + `/get-user?_id=${id}`;
    return this.http.get<User>(url, this.httpOptions);
  }

  getProfile (): Observable<any> {
    const url = this.service.apiUrl + `/profile`;
    return this.http.get<any>(url, this.httpOptions);
  }

  getUserSearch (id_string): Observable<any> {
    const url = this.service.apiUrl + `/search-user?_query=${id_string}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  getUsername (username): Observable<any> {
    const url = this.service.apiUrl + `/get-user-by-username?username=${username}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  updateEmail (data): Observable<any> {
    // console.log('Session Admin');
    // console.log(this.token);
    // console.log(data);
    const url = this.service.apiUrl + `/set-email-notification`;
    return this.http.post<any>(url, data, this.httpOptions);
  }

  registerSessionCompanyRole (data): Observable<any> {
    // console.log('Session Admin');
    // console.log(this.token);
    // console.log(data);
    const url = this.service.apiUrl + `/register-session`;
    return this.http.post<any>(url, data, this.httpOptions);
  }

  getSuperUsers (): Observable<any> {
    const url = this.service.apiUrl + `/get-all-super-users-company`;
    return this.http.get<any>(url, this.httpOptions);
  }

  /* SIFM - Controlar sesión unica por usuario */
  controlConcurrentUser (data): Observable<any> {
    const url = this.service.apiUrl + `/concurrent_user`;
    return this.http.post<any>(url, data, this.httpOptions);
  }
  check_lastactivity_user (data): Observable<any> {
    const url = this.service.apiUrl + `/lastactivity_user`;
    return this.http.post<any>(url, data, this.httpOptions);
  }
  
  register_activesession (data): Observable<any> {
    const url = this.service.apiUrl + `/register_activesession`;
      return this.http.post<any>(url, data, this.httpOptions);
  }

/************************************************/

}



