import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@fury/services/theme.service';
import { UsersService } from '../../services/users/users.service';
import { PrecasosService } from '../../services/precasos/precasos.service';
import { StorageService } from '../../services/guards/storage.service';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;

  name: string;
  username: string;
  company: string;
  role: string;
  version: string;

  companySelect = true;
  roleSelect = true;

  constructor(private router: Router,
              private sidenavService: SidenavService,
              private themeService: ThemeService,
              private userServ: UsersService,
              private precasoServ: PrecasosService,
              private storageService: StorageService) {
  }

  ngOnInit() {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );

    this.name = this.storageService.getName();
    this.username = this.storageService.getUsername();
    this.company = this.storageService.getNameCompany();
    this.role = this.storageService.getRoleSelect();
    this.precasoServ.getMeddraVersion().subscribe(meddra => {
      this.version = meddra.version;
    },
    error => {
      console.log(error);
    });

    if((this.company == null || this.company == '' || this.company == undefined)  &&
    (this.role == null || this.role == '' || this.role == undefined)) {
      this.companySelect = false;
      this.roleSelect = false;
    }

  }

  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {
  }
}
