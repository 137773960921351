import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UsersService } from '../../../services/users/users.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { startWith, map, take } from 'rxjs/operators';

export interface DialogDataConfirm {
  status: boolean;
  title: string;
  text: string;
}

@Component({
  selector: 'fury-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(status): void {
    this.dialogRef.close(status);
  }

}
