import { Injectable } from '@angular/core';
import { JwtHelperService,  } from '@auth0/angular-jwt';
import * as Msal from 'msal';
import * as jwt_decode from '@auth0/angular-jwt';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment'; 
//import { UsersService } from '../../services/users/users.service';
import { uniq } from 'lodash-es';

const msalConfig = {
  auth: {
      clientId: "4e240945-711b-4b7f-b222-547644ab8ae1",
      //clientId: "a0a9bd5e-6c4e-4925-9980-88a6af20a53e",

      authority: "https://login.microsoftonline.com/ntshub.onmicrosoft.com",
      //redirectUri:"https://oiram.ntshub.es:443/",
      redirectUri:"http://localhost:4202/"
  },
  cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
  }
} as any;

const requestObj = {
   scopes: ['api://4e240945-711b-4b7f-b222-547644ab8ae1/access_as_user']
   //scopes: ['api://a0a9bd5e-6c4e-4925-9980-88a6af20a53e/access_as_user']
};

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

 //apiUrl = 'http://localhost:1340/api';  // URL to web api // SIFM SE DESCOMENTA PARA TRABAJAR EN LOCAL
  //apiUrl = 'http://195.53.214.41/api';  // URL to web api
  //apiUrl = 'https://ntshub.es/api';  // URL to web api

  // Production
 //  apiUrl = 'https://api.oiram.ntshub.es/api';  // PRODUCCION

  //Pre-production
  
  //apiUrl = 'https://api.preoiram.ntshub.es/api'; //Pre-production

  // Development apiUrl = 'https://dev.api.oiram.ntshub.es/api';
  //SIFM 
   apiUrl = environment.apiUrl; // URL to web api

  constructor(public jwtHelper: JwtHelperService,
    private storageService: StorageService,
  //  private userServ: UsersService,
    private _router: Router) { console.log('apiUrl:',this.apiUrl); }

  public getTokenExpirationDate(token: string): Date {
    const decoded = this.jwtHelper.decodeToken(token);

    if(decoded !== null){
      if (decoded.exp === undefined) {
        return null;
      }

      const date = new Date(0);

      date.setUTCSeconds(decoded.exp);
      return date;
    } else {
      return undefined;
    }

  }

  public isAuthenticated(): boolean {
    const token = this.storageService.getToken();
    // Check whether the token is expired and return
    // true or false
    const date = this.getTokenExpirationDate(token);

    if (date === undefined) {
      return false;
    }

    return (date.valueOf() > new Date().valueOf());

    // return !this.jwtHelper.isTokenExpired(token);
  }

  logout() {
    
            this.storageService.removeToken();
            this.storageService.removeName();
            this.storageService.removeUsername();
            this.storageService.removeRoleUser();
            this.storageService.removeRoleSelect();
            this.storageService.removeRoleSelectId();
            this.storageService.removeIdCompany();
            this.storageService.removeNameCompany();
            this.storageService.removeOrgId();
            this.storageService.removeIdUser();
            this.storageService.removeActionRole();
            //this._router.navigate(['/']);     //PARA DESARROLLO DESCOMENTAR ESTA LINEA
            window.location.href ="https://oiram.ntshub.es"; //PARA PRODUCCION DESCOMENTAR ESTA LINEA 
       
  }

}
