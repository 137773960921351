import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              private themeService: ThemeService,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private route: ActivatedRoute) {
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }      

  }

  ngOnInit(){
    this.sidenavService.addItems([
      {
        name: 'Dashboard',
        routeOrFunction: '/dashboard',
        icon: 'dashboard',
        position: 10,
        pathMatchExact: true
      },
      {
        name: 'Meddra',
        routeOrFunction: '/update-meddra',
        icon: 'insert_drive_file',
        position: 11,
        role: 'Admin-Audit'
      },
      {
        name: 'Companies',
        //routeOrFunction: '/companies',
        icon: 'business',
        position: 15,
        role: 'Admin',
        subItems: [
          {
            name: 'List Companies',
            routeOrFunction: '/companies/list-companies',
            position: 10,
            role: 'Admin',
          },
          {
            name: 'Create Companies',
            routeOrFunction: '/companies/create-companies',
            role: 'Admin',
            position: 15
          }
        ]
      },
      {
        name: 'My company',
        routeOrFunction: '/companies/my-company',
        icon: 'business',
        position: 15,
        role: 'QPPV-Audit-Starter-Management-Quality Review'
      },
      {
        name: 'Users',
        //routeOrFunction: '/companies',
        icon: 'people',
        position: 20,
        role: 'Admin-QPPV-Audit',
        subItems: [
          {
            name: 'List Users',
            routeOrFunction: '/users/list-users',
            role: 'Admin',
            position: 10
          }
        ]
      },
      {
        name: 'Pre-case',
        routeOrFunction: '/precase',
        icon: 'library_books',
        position: 30,
        role: 'QPPV-Starter',
        subItems: [
          {
            name: 'Pre-case List',
            routeOrFunction: '/precase/precase-list',
            position: 10
          },
          {
            name: 'Create Pre-case',
            routeOrFunction: '/precase/create-precase',
            position: 15
          }
        ]
      },
      {
        name: 'Pre-case',
        routeOrFunction: '/precase',
        icon: 'library_books',
        position: 30,
        role: 'Audit-Management-Quality Review',
        subItems: [
          {
            name: 'Pre-case List',
            routeOrFunction: '/precase/precase-list',
            position: 10
          }
        ]
      },
      {
        name: 'Case',
        routeOrFunction: '/case',
        icon: 'description',
        position: 35,
        role: 'Audit',
        subItems: [
          {
            name: 'Case List',
            routeOrFunction: '/case/case-list',
            position: 10
          }
        ]
      },
      {
        name: 'Case',
        routeOrFunction: '/case',
        icon: 'description',
        position: 35,
        role: 'QPPV-Management-Quality Review',
        subItems: [
          {
            name: 'Case List',
            routeOrFunction: '/case/case-list',
            position: 10
          }
        ]
      },
      {
        name: 'Audit Trails',
        routeOrFunction: '/audit-trails',
        icon: 'assignment_turned_in',
        position: 40,
        role: 'QPPV-Audit-Starter-Management-Quality Review',
        subItems: [
          {
            name: 'Audit Case / Precase',
            routeOrFunction: '/audit-trails/audit-precase',
            position: 10
          },
          {
            name: 'Audit Session',
            routeOrFunction: '/audit-trails/audit-session',
            position: 15
          },
          {
            name: 'Audit Actions',
            routeOrFunction: '/audit-trails/audit-actions',
            position: 20
          }
        ]
      },
      {
        name: 'Medicines',
        routeOrFunction: '/medicines',
        icon: 'local_hospital',
        position: 45,
        role: 'QPPV-Audit',
        subItems: [
          {
            name: 'List Medicines',
            routeOrFunction: '/medicines/list-medicines',
            position: 10
          },
          {
            name: 'Upload Medicines',
            routeOrFunction: '/medicines/upload-medicines',
            position: 15
          }
        ]
      },
      {
        name: 'Reports',
        routeOrFunction: '/reportes',
        icon: 'picture_as_pdf',
        position: 45,
        role: 'QPPV-Audit-Management-Quality Review',
        subItems: [
          {
            name: 'PSUR',
            routeOrFunction: '/reportes/generar-psur',
            position: 10
          },
          {
            name: 'DSUR',
            routeOrFunction: '/reportes/generar-dsur',
            position: 10
          }
        ]
      },
      {
        name: 'Import Case',
        routeOrFunction: '/import-case',
        icon: 'insert_drive_file',
        position: 50,
        role: 'QPPV-Audit-Starter-Management-Quality Review'
      },
      {
        name: 'Categorize',
        routeOrFunction: '/categorize',
        icon: 'category',
        position: 50,
        role: 'Categorizer'
      },
      {
        name: 'List of requests received',
        routeOrFunction: '/role-requests/list-role-requests',
        icon: 'list',
        position: 15,
        role: 'QPPV'
      },
      {
        name: 'Create Role Requests',
        routeOrFunction: '/role-requests/create-role-requests',
        icon: 'person_add',
        position: 16,
        role: 'Audit-Starter-Management-Quality Review'
      }
    ]);
  }
}
