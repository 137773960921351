import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /* Getters sessionStorage */
  getToken() {
    return sessionStorage.getItem('msal.token');
  }

  getName() {
    return sessionStorage.getItem('name');
  }

  getUsername() {
    return sessionStorage.getItem('username');
  }

  getRoleUser() {
    return sessionStorage.getItem('rolUser');
  }

  getRoleSelect() {
    return sessionStorage.getItem('roleSelect');
  }

  getRoleSelectId() {
    sessionStorage.getItem('roleSelectId');
  }

  getIdCompany() {
    return sessionStorage.getItem('id_company');
  }

  getNameCompany() {
    return sessionStorage.getItem('companySelect');
  }

  getOrgId() {
    return sessionStorage.getItem('org_id');
  }

  getIdUser() {
    return sessionStorage.getItem('idUser');
  }

  getActionRole() {
    return sessionStorage.getItem('actionRole');
  }

  /* Getters sessionStorage */

  /* Setters sessionStorage */
  setToken(token) {
    sessionStorage.setItem('msal.token', token);
  }

  setName(name) {
    sessionStorage.setItem('name', name);
  }

  setUsername(username) {
    sessionStorage.setItem('username', username);
  }

  setRoleUser(rolUser) {
    sessionStorage.setItem('rolUser', rolUser);
  }

  setRoleSelect(roleSelect) {
    sessionStorage.setItem('roleSelect', roleSelect);
  }

  setRoleSelectId(roleSelectId) {
    sessionStorage.setItem('roleSelectId', roleSelectId);
  }

  setIdCompany(id_company) {
    sessionStorage.setItem('id_company', id_company);
  }

  setNameCompany(companySelect) {
    sessionStorage.setItem('companySelect', companySelect);
  }

  setOrgId(org_id) {
    sessionStorage.setItem('org_id', org_id);
  }

  setIdUser(idUser) {
    sessionStorage.setItem('idUser', idUser);
  }

  setActionRole(actionRole) {
    sessionStorage.setItem('actionRole', actionRole);
  }
  /* Setters sessionStorage */

  /* Remove sessionStorage */
  removeToken() {
    sessionStorage.removeItem('msal.token');
  }

  /*SIFM - Remove selected token */
  removeselectedToken(token) {
    sessionStorage.removeItem(token);
  }

  removeName() {
    sessionStorage.removeItem('name');
  }

  removeUsername() {
    sessionStorage.removeItem('username');
  }

  removeRoleUser() {
    sessionStorage.removeItem('rolUser');
  }

  removeRoleSelect() {
    sessionStorage.removeItem('roleSelect');
  }

  removeRoleSelectId() {
    sessionStorage.removeItem('roleSelectId');
  }

  removeIdCompany() {
    sessionStorage.removeItem('id_company');
  }

  removeNameCompany() {
    sessionStorage.removeItem('companySelect');
  }

  removeOrgId() {
    sessionStorage.removeItem('org_id');
  }

  removeIdUser() {
    sessionStorage.removeItem('idUser');
  }

  removeActionRole() {
    sessionStorage.removeItem('actionRole');
  }
  /* Remove sessionStorage */

}
