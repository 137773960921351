import { Injectable } from '@angular/core';
import { JwtHelperService,  } from '@auth0/angular-jwt';
import * as Msal from 'msal';
import * as jwt_decode from '@auth0/angular-jwt';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UsersService } from '../users/users.service';
import { uniq } from 'lodash-es';

const msalConfig = {
  auth: {
      clientId: "4e240945-711b-4b7f-b222-547644ab8ae1",
     //clientId: "a0a9bd5e-6c4e-4925-9980-88a6af20a53e",
      authority: "https://login.microsoftonline.com/ntshub.onmicrosoft.com",
      //redirectUri: "https://localhost/", //
      redirectUri: "https://oiram.ntshub.es:443/"  //PRODUCCION OIRAM.NTSHUB.ES
  },
  cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
  }
} as any;

const requestObj = {
   scopes: ['api://4e240945-711b-4b7f-b222-547644ab8ae1/access_as_user']
   //scopes: ['api://a0a9bd5e-6c4e-4925-9980-88a6af20a53e/access_as_user']
};

@Injectable({
  providedIn: 'root'
})
export class LogoutService {


  constructor(public jwtHelper: JwtHelperService,
    private storageService: StorageService,
    private userServ: UsersService,
    private redirect: Router,
    ) { console.log('apiUrl:',"database session killed"); }


  logout() {
      /* SIFM - matamos la sesion activa en bbdd*/
      
      let user_id=this.storageService.getIdUser();
      let register_action=1;
      const kill=0;
      const token=this.storageService.getToken();
      let data={user_id,register_action,kill,token};


      this.userServ.register_activesession(data)
       .subscribe(response2 => {
            this.storageService.removeToken();
            this.storageService.removeName();
            this.storageService.removeUsername();
            this.storageService.removeRoleUser();
            this.storageService.removeRoleSelect();
            this.storageService.removeRoleSelectId();
            this.storageService.removeIdCompany();
            this.storageService.removeNameCompany();
            this.storageService.removeOrgId();
            this.storageService.removeIdUser();
            this.storageService.removeActionRole();
            //window.location.href ="http://localhost:4200"; //PARA DESARROLLO DESCOMENTAR ESTA LINEA
            window.location.href ="https://oiram.ntshub.es"; //PARA PRODUCCION DESCOMENTAR ESTA LINEA
            //this.redirect.navigate. .navigateByUrl("https://oiram.ntshub.es/")
          }
          ,
       error => {
         console.log('Error Session', error);
       });

      /*******************************************/

   
  }

}
